<template>
  <!-- 链接 -->
  <div id="link-box" class="complie-addmodel-remark">
    <div class="complie-titel-top">
      <div class="complie-titel-center">
        <div>
          <img
            src="../../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_link_af.png"
            alt=""
          />
        </div>
        <div class="complie-titel-text">
          {{ getString(strings.Mind_Edit_Node_Menu_Ai_Label) }}
        </div>
      </div>

      <!-- <div class="complie-right-hide">
        <a-button type="primary" @click="onSubmit">
          {{ getString(strings.Mind_Group_Accomplish) }}
        </a-button>
      </div> -->
      <div class="complie-right-hide" @click="onSubmit">
        <a-icon type="close" :style="{ fontSize: '16px' }" />
      </div>
    </div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="addLinkMessage">
        <a-form-model-item>
          <a-input
            v-model="form.linkData"
            style="resize: none"
            class="complie-content"
            :placeholder="getString(strings.Mind_Ai_Input_Hint_Label)"
            @focus="focusInput()"
            @blur="blurInput()"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            style="height: 38px"
            type="primary"
            @click="onAddAiMessageList"
          >
            提交
          </a-button>
        </a-form-model-item>
      </div>
      <div class="link-Message-List">
        <div
          class="link-message-list-box"
          v-show="showContent"
        >
            <div class="ai-content-text">
                <div class="content-titel-text">
                {{resultTitle}}
                </div>
                <div class="content-label-text">
                {{resultContent}}
                </div>
            </div>
            <div class="ai-content-operate">
                <div class="ai-content-copy">
                    <a-button type="primary" @click="onCopy">
                    {{ getString(strings.Mind_Edit_Node_Menu_Copy) }}
                    </a-button>
                </div>
                <div class="ai-content-to-map">
                    <a-button type="primary" @click="toMap">
                    {{ getString(strings.Mind_Ai_To_Map_Label) }}
                    </a-button>
                </div>
            </div>
        </div>
        <div class="link-hide-data" v-show="!showContent">
          <img
            src="../../../../assets/img/mapIcon/res/mipmap-xhdpi/hide_link.png"
            alt=""
          />
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
}
import { mapMutations } from "vuex";
import complieAddModelRemark from "../../../../assets/css/complieAddModelRemark/complieAddModelRemark.less";
import getString from "../../../../common/lang/language";
import strings from "../../../../common/lang/strings";
import { postMindmapGetAi } from '../../../../common/netWork/mind_map_api';
import Util from '../../../../utils/Util';
import MindMe from '../../../../viewmodel/facade/MindMe';
import EditMindmapVM from '../../../../viewmodel/mindelementdata/vm/EditMindmapVM';
export default {
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 20 },
      form: {
        linkData: null,
      },
      resultTitle: "",
      resultContent: "",
      linkListData: [],
      linkListKey: 0,
      radioAccout: false,
      showContent: false,
      strings,
      aiLoadingTimes: -1,
      aiLoadStartTime: -1,
      aiLoadingTimesLael: "",
      aiLoadingTimesWithOutTimeLael: "",
      selectedNodeId: -1,
    };
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "setShowAddAi",
      "setRemoveLinkContent",
      "setNodeContentMessage",
      "setUseShortcut",
      "isShowShoppingModel",
      "showLoginModel",
    ]),
    getString(i) {
      return getString(i);
    },
    //隐藏链接模态框
    onHideLinkModel() {
        console.log("onHideLinkModel");
      this.setShowAddAi(false);
      this.form.linkData = null;
    },
    //添加链接
    
    aiLoading() {        
        let wait1 = getString(strings.Mind_Ai_Wait_1_Label)
        let wait2 = getString(strings.Mind_Ai_Wait_2_Label)
        let wait3 = getString(strings.Mind_Ai_Wait_3_Label)
        let wait4 = getString(strings.Mind_Ai_Wait_4_Label)
        let wait5 = getString(strings.Mind_Ai_Wait_5_Label)
        let currentTime = Util.getCurrentTimeInsecond()
        if (currentTime - this.aiLoadStartTime > 5 && this.aiLoadingTimesWithOutTimeLael.indexOf(wait1) == -1) {
            this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesWithOutTimeLael + " " + wait1 + " "
        } else if (currentTime - this.aiLoadStartTime > 10 && this.aiLoadingTimesWithOutTimeLael.indexOf(wait2) == -1) {
            this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesWithOutTimeLael + " " + wait2 + " "
        } else if (currentTime - this.aiLoadStartTime > 15 && this.aiLoadingTimesWithOutTimeLael.indexOf(wait3) == -1) {
            this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesWithOutTimeLael + " " + wait3 + " "
        } else if (currentTime - this.aiLoadStartTime > 20 && this.aiLoadingTimesWithOutTimeLael.indexOf(wait4) == -1) {
            this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesWithOutTimeLael + " " + wait4 + " "
        } else if (currentTime - this.aiLoadStartTime > 25 && this.aiLoadingTimesWithOutTimeLael.indexOf(wait5) == -1) {
            this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesWithOutTimeLael + " " + wait5 + " "
        }

        this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesWithOutTimeLael + "."
        this.aiLoadingTimesLael = this.aiLoadingTimesWithOutTimeLael
        if (currentTime - this.aiLoadStartTime > 1) {
            this.aiLoadingTimesLael = this.aiLoadingTimesLael + "  " + (currentTime - this.aiLoadStartTime) + "s"
        }
        this.resultContent = this.aiLoadingTimesLael;
    },

    clearLoadingInterval() {
       clearInterval(this.aiLoadingTimes) 
       this.aiLoadingTimesWithOutTimeLael = ""
       this.aiLoadStartTime = -1;
       this.aiLoadingTimes = -1;
    },

    onAddAiMessageList() {
        let label = this.form.linkData;
        if (this.aiLoadingTimes > -1) {
            if (label != null && label.length == 0) {
                this.clearInputAndContent();
            }
            return
        }
        if (!MindMe.isLogin()) {
            this.showLoginModel(true);
            return
        }
        
        if (label == null || label.length == 0) {
            this.clearInputAndContent();
            this.openUpgrad();
            return;
        }
        let times = MindMe.getAiMindUseTimes();
        if (times >= 3) {
            let memberExpire = MindMe.getMemberExpire()
            if (memberExpire == 0 && times >= 3) {
                this.openUpgrad();
                return;
            } else if (memberExpire > 0 && memberExpire < 365 * 10 && times >= 15) {
                this.openUpgrad();
                return;
            } else if (memberExpire >= 365 * 10 && times >= 50) {
                this.openExceedingLimit();
                return;
            }
        }
        
        let requestLabel = label
        if (requestLabel.length > 0) {
            requestLabel = requestLabel + ", " + getString(strings.Mind_Ai_Submit_Content_Psuffix_Label)
        }

        this.clearLoadingInterval();
        let tath = this;
        this.aiLoadingTimesLael = getString(strings.Mind_Ai_Wait_0_Label) + "."
        this.aiLoadingTimesWithOutTimeLael = this.aiLoadingTimesLael        
        this.resultContent = this.aiLoadingTimesLael;

        this.aiLoadStartTime = Util.getCurrentTimeInsecond()
        this.aiLoadingTimes = setInterval(() => {
            tath.aiLoading()
        }, 500);

        postMindmapGetAi({ data: requestLabel}, (res) => {
            //   console.log("res-> ", res);
          this.resultContent = res.data;
          this.clearLoadingInterval();
        }, (error) => {
            this.clearLoadingInterval();
            this.$message.error(error.desc);
            if (error.code == 182) {
                this.serviceUpgradedTips()
            } else if (error.code == 94) {
                this.openUpgrad()
            }
        })
        MindMe.setAiUseTimes();
        this.resultTitle = label
        this.showContent = true;
    },

    openUpgrad() {
        let that = this;
        let tips = ""
        if (MindMe.isUpgrade()) {
          tips = getString(strings.Mind_Ai_To_Senior_Member_Label)
        } else {
          tips = getString(strings.Mind_Ai_To_Member_Label)
        }
        this.$confirm({
        title: getString(strings.Mind_Edit_Upgrade),
        content: tips, //充值会员可获得更多使用次数。
        centered: true,
        maskClosable: true,
        okText: getString(strings.Mind_Go_Now),
        cancelText: getString(strings.Global_Cancel),
        onOk() {
            that.upgradeMember();
        },
        onCancel() {},
        });
    },

    serviceUpgradedTips() {
        let that = this;
        let tips = getString(strings.Mind_Ai_Service_Upgraded_Label)

        this.$confirm({
        title: getString(strings.Mind_Tips),
        content: tips, //充值会员可获得更多使用次数。
        centered: true,
        maskClosable: false,
        okText: getString(strings.Global_Ok),
        cancelText: getString(strings.Global_Cancel),
        onOk() {
        },
        onCancel() {},
        });
    },

    openExceedingLimit() {
        let that = this;
        this.$confirm({
        title: getString(strings.Mind_Edit_Upgrade),
        content: getString(strings.Mind_Ai_Exceeding_Limit_Label),
        centered: true,
        maskClosable: true,
        okText: getString(strings.Global_Ok),
        cancelText: getString(strings.Global_Cancel),
        onOk() {},
        onCancel() {},
        });
    },

    upgradeMember() {
      //升级会员
      this.isShowShoppingModel(true);
    },

    toMap() {
        if (this.aiLoadingTimes > -1) {
            this.$message.error("请获得结果在重试 ", err);
            return;
        }
        let values = this.resultTitle + " \n " + this.resultContent
        
        EditMindmapVM.replaceNodeString(this.selectedNodeId, values, true, true)

        this.clearInputAndContent();
        this.setShowAddAi(false);
    },

    clearInputAndContent() {
        this.resultTitle = "";
        this.resultContent = "";
        this.showContent = false;
        this.clearLoadingInterval();
        this.form.linkData = null;      
    },

    onCopy() {
        if (this.aiLoadingTimes > -1) {
            this.$message.error("请获得结果在重试 ", err);
            return;
        }
        let values = this.resultTitle + " \n" + this.resultContent
        const copyText = async () => {
            try {
              await navigator.clipboard.writeText(values);
              this.$message.success(
                getString(strings.Message_Tips_Copy_Success)
              );
            } catch (err) {
              this.$copyText(values).then(
                (res) => {
                  this.$message.success(
                    getString(strings.Message_Tips_Copy_Success)
                  );
                },
                (err) => {
                  this.$message.error("Failed to copy ", err);
                }
              );
            }
        };
        copyText();
    },
    //删除连接按钮是否显示
    onRadioClickAccout() {
      this.radioAccout = !this.radioAccout;
    },
    //删除链接
    onRadioRemove() {
      this.resultTitle = ""
      this.resultContent = ""
      this.showContent = false;
    },
    //确认
    onSubmit() {
        this.clearLoadingInterval();
        this.setShowAddAi(false);
        this.form.linkData = null;
    },    
    //将vuex存储链接对象置空
    nodeContentMessage() {
      this.setNodeContentMessage(null);
    },
    focusInput() {
      this.setUseShortcut(false);
    },
    blurInput() {
      this.setUseShortcut(true);
    },
  },

  props: {
    aiSelectedNodeId: {
        type: Number,
        defualt() {
            return -1;
        },
        },
  },

  watch: {
    aiSelectedNodeId(newId) {
      this.selectedNodeId = newId;
    },
  },
};
</script>

<style lang="less" scoped>
.complie-addmodel-remark {
  width: 524px;
  height: 474px;
  border-radius: 9px;
  z-index: 999;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  .link-message-list-bottom {
    border-top: 1px solid #e8e8e8;
    width: 100%;
  }
  .link-hide-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .link-message-top {
    position: relative;
    height: 20px;
    .link-message-edit {
      font-size: 16px;
      position: absolute;
      left: 0;
      transition: 0.5s;
      cursor: pointer;
    }
    .link-message-edit:hover {
      font-size: 16px;
      position: absolute;
      left: 0;
      color: #fd492b;
      transition: 0.5s;
    }
    .link-message-cancel {
      font-size: 16px;
      position: absolute;
      right: 0;
      transition: 0.5s;
      cursor: pointer;
    }
    .link-message-cancel:hover {
      font-size: 16px;
      position: absolute;
      right: 0;
      color: #fd492b;
      transition: 0.5s;
    }
  }
  .complie-titel-top {
    width: 524px;
    height: 60px;
    position: relative;
    .complie-titel-center {
      display: flex;
      // justify-content: center;
      background: #efefef;
      padding: 0 15px;
      align-items: center;
      .complie-titel-text {
        width: 200px;
        height: 25px;
        font-size: 18px;
        /*font-weight: bold;*/
        color: #333333;
        opacity: 1;
        margin: 10px;
        display: flex;
        align-items: center;
      }
    }
    .complie-right-hide {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 50%;
      top: 13px;
      right: 15px;
      cursor: pointer;
      transition: 0.3s;
    }
    .complie-right-hide:hover {
      background-color: #bbbbbb;
    }
  }
  .content-titel-text {
    display: flex;
    left: 0px;
    top: 20px;
    right: 0px;
    margin-top: 5px;
    font-size: 24px;
    font-weight: 700;
    color: #222222;
    width: 100%;
  }
  .content-label-text {
    display: flex;
    left: 0;
    top: 20px;
    right: 0px;
    margin-top: 8px;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 2;
    color: #333333;
    width: 100%;
    white-space:pre-wrap;
    text-indent: -5px
  }

  .ai-content-text {
    display: block;
    left: 0px;
    top: 20px;
    right: 0px;
    width: 100%;
    bottom: 100px;
    overflow: auto;
    height: 300px;
    user-select:text
  }
  .ai-content-operate {
    display: flex;
    left: 20px;
    right: 20px;
    bottom: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .ai-content-copy {
        display: flex;
        left: 20px;
        height: 34px;
        bottom: 0px;
        button {
            height: 34px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            border-top-left-radius: 17px;
            border-bottom-left-radius: 17px;
            border: 2px solid #fd492b;
            background: #ffffff;
            color: #fd492b;
            font-weight: 700;
        }
    }
    .ai-content-to-map {
        display: flex;
        right: 20px;
        width: 120px;
        height: 34px;
        bottom: 0px;
        button {
            height: 34px;
            border-bottom-right-radius: 17px;
            border-top-right-radius: 17px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            background: #fd492b;
            font-weight: 700;
        }
    }
  }
  .complie-content {
    width: 402px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 30px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
    padding-left: 20px;

    &::placeholder {
          color: #6a6a6a;
        }
  }
  .complie-btn {
    width: 524px;
    position: absolute;
    bottom: 20px;
    button {
      height: 32px;
      border-radius: 2px;
    }
  }
}

.link-Message-List {
  margin: 10px 30px;
  width: 464px;
  height: 350px;
  overflow: hidden;
  position: absolute;
  top: 100px;
  .ant-form-item-children {
    width: 464px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .link-Message-Content {
      width: 349px;
      height: 58px;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      // border: none;
      // outline: none !important;
    }
    .ant-input:focus {
      border: none;
      outline: none !important;
      box-shadow: none;
    }
    label {
      position: absolute;
      right: 0px;
      line-height: 58px;
    }
  }
}
.ant-form-item {
  margin-bottom: 0px;
}
.addLinkMessage{
    button{
        width: 62px;
        height: 38px;
        border-bottom-right-radius: 19px;
        border-top-right-radius: 19px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        position: absolute;
        background: #fd492b;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -2px;
    }
}
</style>